import './BarInformation.css';

const BarInformation = () => {
  return (
    <div className='header-information'>
        <p>Centre neurologique du Ponant | Polyclinique de Keraudren | Ouvert du lundi au vendredi de 9h à 12h et de 14h à 17h</p>
        <h2>Prendre rendez-vous : <span>02 52 32 38 18</span></h2>
    </div>  
  )
}

export default BarInformation