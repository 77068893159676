import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import "./Diseases.css"

const Diseases = () => {
  return (
    <div className="Super-Diseases">
      <Header/>
      <div className='Diseases'>

        <div className='Diseases-introduction'>
          <h1>Les pathologies</h1>
          <p>Les troubles neurologiques affectent le système nerveux central (cerveau et moelle épinière), périphérique (nerfs) et musculaire. Les symptômes pris en charge par les médecins neurologues sont donc nombreux : faiblesse musculaire, engourdissement, déficit sensoriel, troubles cognitifs, malaise, vertiges, mouvements involontaires, douleurs et maux de tête. Les origines de ces maladies sont nombreuses : vasculaire, inflammatoire, auto-immune, dégénérative, génétique, infectieuse, traumatique. </p>
        </div>

        <div className='Diseases-informations'>
          <h2>Accident Vasculaire Cérébral (AVC)</h2>
          <p>L’AVC se définit par la survenue brutale d’un déficit neurologique. Ce déficit est lié à la perte d’une fonction cérébrale secondaire à une atteinte vasculaire. Celle-ci peut être ischémique (occlusion d’une artère) dans 80% des cas ou hémorragique (rupture d’un vaisseau) dans 20% des cas. A noter que parfois, l’occlusion artérielle peut-être transitoire de même que le symptôme neurologique qui en résulte. On parle dans ce cas d’accident ischémique transitoire (AIT).</p>
          <p>L’AVC est une maladie grave qui survient brutalement mais dont les conséquences peuvent être importantes et durables. Il s’agit en France de la première cause de handicap chez l’adulte, de la première cause de mortalité chez la femme et la seconde chez l’homme et de la deuxième cause de troubles de la mémoire (après la maladie d’Alzheimer).</p>
          <p>A noter que les neurologues du centre sont amenés à proposer un suivi des patients dans le cadre d’une prévention secondaire suite à un AVC mais que tout signe neurologique aigu évoquant un AVC doit conduire à se rendre aux urgences d’un centre hospitalier de référence après régulation par le centre 15.</p>
        </div>

        <div className='Diseases-informations'>
          <h2>Sclérose en plaques (SEP)</h2>
          <p>Il s’agit d’une maladie inflammatoire, auto-immune, qui occasionne des lésions ciblées du système nerveux central (cerveau et moelle épinière). Les symptômes qui en découlent sont donc variables (déficit moteur et/ou sensitif, troubles de la coordination, troubles urinaires, etc…). Ils apparaissent généralement progressivement et ont tendance à régresser au moins partiellement en quelques jours ou semaines : on parle de poussée. Des séquelles peuvent néanmoins subsister entre les poussées. C’est une maladie plus fréquente chez l’adulte jeune, en particulier chez les femmes.</p>
          <p>A noter qu’il existe une forme dite progressive où les symptômes évoluent continuellement, indépendamment des poussées qui peuvent se surajouter.</p>
        </div>

        <div className='Diseases-informations'>
          <h2>Maladie d’Alzheimer et maladies apparentées</h2>
          <p>Les maladies neurodégénératives sont caractérisées par la dégénérescence progressive, accélérée en comparaison au vieillissement naturel des cellules neuronales. Les mécanismes plus spécifiques de ces pathologies sont complexes et imparfaitement connus. En résumé, certains neurones vont vieillir et mourir prématurément. En fonction de leur localisation, le patient va décrire de manière plus marquée des troubles de la mémoire (par exemple dans la maladie d’Alzheimer) ou des troubles du comportement (par exemple dans la dégénérescence fronto-temporale). </p>
          <p>Parmi les plaintes les plus fréquentes, on peut noter les troubles mnésiques, c’est-à-dire des altérations de la mémoire. Celles-ci vont généralement d’abord rendre difficile le souvenir des faits récents avant de se diffuser à la mémoire plus ancienne. Le patient va souvent être désorienté dans le temps et/ou dans l’espace. Il existe aussi des troubles attentionnels, occasionnant des problèmes de concentration. Des troubles du langage sont aussi possibles, avec des difficultés pour le patient à trouver ses mots mais aussi à comprendre son entourage. On note également des troubles praxiques et des troubles exécutifs ce qui se traduit concrètement par des difficultés à planifier des tâches et à les effectuer. Enfin, il est fréquent d’observer des modifications de la personnalité ou des troubles de l’humeur.</p>
          <p>Plusieurs facteurs semblent impliqués dans ces maladies. En plus du vieillissement, il y a des facteurs de prédisposition génétique mais aussi des facteurs modifiables comme les facteurs de risque vasculaire (hypertension artérielle, diabète, hypercholestérolémie). A l’inverse, un bon niveau d’étude, d’insertion sociale, de loisirs et de pratique physique et sportive sont des éléments protecteurs. </p>
        </div>

        <div className='Diseases-informations'>
          <h2>Maladie de Parkinson</h2>
          <p>Elle correspond à une maladie dégénérative des circuits notamment impliqués dans le contrôle des mouvements. Les symptômes typiques comprennent un tremblement au repos, un ralentissement des mouvements voire des blocages et une rigidité des membres. Elle occasionne aussi des troubles de la marche avec des pas raccourcis et une tendance à se pencher vers l’avant. Il peut se développer en parallèle des signes plus diffus comme des troubles du sommeil, des troubles cognitifs ou des troubles du comportement voire des hallucinations.</p>
          <p>Si l’adulte jeune peut parfois être atteint, il s’agit principalement d’une maladie touchant les patients de plus de 60 ans. L’âge est donc le facteur de risque principal. Dans une moindre mesure, l’exposition chronique aux pesticides est également un facteur de risque. Enfin, il y a également des formes génétiques.</p>
        </div>

        <div className='Diseases-informations'>
          <h2>Neuropathies périphériques</h2>
          <p>Elles correspondent à une atteinte des nerfs issus de la moelle épinière. Les symptômes fréquents sont une faiblesse motrice et/ou un engourdissement dans le territoire d’un nerf ou d’une racine nerveuse. Il peut y avoir fréquemment des douleurs associées dites « neuropathiques » comme des décharges électriques ou des sensations de brûlures. De nombreuses causes peuvent occasionner ce type d’atteinte allant de cause locorégionale avec l’exemple le plus connu du syndrome du canal carpien jusqu’au causes générales inflammatoires en passant par des causes infectieuses, métaboliques ou tumorales.</p>
        </div>


        <div className='Diseases-informations'>
          <h2>Epilepsie</h2>
          <p>L'épilepsie est une affection neurologique caractérisée par une activité électrique cérébrale anormale et excessive, déclenchant des crises paroxystiques et imprévisibles. Ces crises peuvent se manifester de différentes manières, telles que des convulsions, des absences momentanées ou des sensations inhabituelles. Elles peuvent affecter les mouvements, les sensations, la conscience ou le comportement de la personne touchée.</p>
        </div>

        <div className='Diseases-informations'>
          <h2>Les maux de tête ou céphalées</h2>
          <p>Elles correspondent à l’ensemble des pathologies occasionnant des douleurs de la tête ou de la face. Il s’agit d’un symptôme invalidant qui peut être secondaire à une cause sous-jacente (traumatisme, accident vasculaire, hypertension intracrânienne) ou bien primaire c’est-à-dire spontanée (comme la migraine ou la céphalée de tension).</p>
        </div>

      </div>
      <Footer/>
    </div>
  )
}

export default Diseases